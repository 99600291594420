<template>
  <div class="myorder">
    <!-- 退款遮罩层 -->
    <van-popup v-model="goodsReturnedNoteshow">内容</van-popup>

    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>

    <van-dialog
      v-model="showDel"
      show-cancel-button
      class="del-tip"
      @confirm="delCollect()"
    >
      <div class="del-msg">
        <p class="showDel-text">确认取消该订单吗?</p>
      </div>
    </van-dialog>

    <van-nav-bar
      title="我的订单"
      left-arrow
      @click-left="onClickLeft"
      fixed
      :z-index="101"
      class="dark_bg"
    />
    <!-- 订单 -->
    <van-tabs
      v-model="active"
      title-inactive-color="#333"
      swipeable
      :border="false"
      class="myorder-tab dark_vant_black"
      @change="onChange"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$globalConfig.finishedText"
        @load="onLoad"
        :error.sync="error"
        offset:50
        :immediate-check="checkis"
      >
        <van-tab :title="tabs" v-for="tabs in tabarr" :name="tabs" :key="tabs">
          <ul class="myorder-list">
            <li
              v-for="item in orderList.items"
              :key="item.id"
              @click="goOrderDetail(item)"
              class="dark_shop_bg"
            >
              <p>
                <span
                  class="iconfont icon-dianpu1"
                  style="font-size: 1.2 rem"
                ></span>
                <span>{{ item.status | status }}</span>
              </p>
              <div>
                <van-card
                  :num="item.skuList[0].num"
                  :title="item.skuList[0].spuTitle"
                  :thumb="item.skuList[0].img"
                  :desc="item.skuList[0].title"
                  class="dark_shop_bg dark_text"
                  style="font-size: 0.26rem"
                >
                  <span slot="price" style="color: #7d7e80"
                    >￥{{ (item.skuList[0].price / 100).toFixed(2) }}</span
                  >
                  <div slot="footer" v-if="item.status == 40">
                    <!-- <van-button
                      round
                      type="warning"
                      plain
                      size="mini"
                      style="padding:0 0.5rem;"
                      @click.stop="goBill(item.id)"
                      v-if="item.invoiceStatus===''"
                    >申请开票</van-button>
                    <van-button
                      round
                      type="warning"
                      plain
                      size="mini"
                      style="padding:0 0.5rem;"
                      v-if="item.invoiceStatus==='1'||item.invoiceStatus==='0'||item.invoiceStatus==='2'"
                      @click.stop="goBillDetail(item.id)"
                    >查看发票</van-button> -->
                    <van-button
                      size="mini"
                      type="warning"
                      round
                      plain
                      style="padding: 0 0.5rem"
                      @click.stop="goComment(item)"
                      >去评价</van-button
                    >
                  </div>
                </van-card>
              </div>
              <section>
                <p style="font-size: 0.26rem">
                  共{{ item.skuList[0].num }}件商品&emsp;合计：￥{{
                    (item.actualPrice / 100).toFixed(2)
                  }}
                </p>
                <p style="font-size: 0.26rem">下单时间：{{ item.time }}</p>
                <p>
                  <van-button type="default" class="myorder-btn"
                   v-if="!item.invoiceId && (item.status == '20' || item.status == '30' || item.status == '40' || item.status == '50') "
                  @click.stop="Invoice(item)"
                  >开具发票</van-button>
                  <van-button type="default" class="myorder-btn"
                    v-if="item.invoiceId && (item.status == '20' || item.status == '30' || item.status == '40' || item.status == '50')"
                    @click.stop="viewInvoice(item.invoiceId)"
                  >查看发票</van-button>
                  <van-button type="default" class="myorder-btn"
                   v-if="item.status == '20'"
                    @click.stop="cancelOrder(item.orderNo)"
                  >取消订单</van-button>
                  <van-button type="default" class="myorder-btn"
                    v-if="item.status == '50'"
                    @click.stop="payRefundOrder(item)"
                  >申请退款</van-button>
                  <van-button type="default" class="myorder-btn"
                    v-if="item.status == '10'"
                    @click.stop="goPay(item)"
                  >去付款</van-button>
                  <!-- <van-button
                    round
                    type="default"
                    hairline
                    plain
                    size="mini"
                    style="padding: 0 0.5rem; border-color: rgb(105, 103, 103)"
                    v-if="!item.invoiceId && (item.status == '20' || item.status == '30' || item.status == '40' || item.status == '50') "
                    @click.stop="Invoice(item)"
                    >开具发票</van-button
                  > -->
                  <!-- <van-button
                    round
                    type="default"
                    hairline
                    plain
                    size="mini"
                    style="padding: 0 0.5rem; border-color: rgb(105, 103, 103)"
                    v-if="item.invoiceId && (item.status == '20' || item.status == '30' || item.status == '40' || item.status == '50')"
                    @click.stop="viewInvoice(item.invoiceId)"
                    >查看发票</van-button
                  > -->
                    <!-- @click.stop="cancelOrder(item.orderNo)" -->

                  <!-- <van-button
                    round
                    type="default"
                    hairline
                    plain
                    size="mini"
                    v-if="item.generalStatus=='2'||item.generalStatus=='3'"
                    style="padding:0 0.5rem;border-color:rgb(105, 103, 103);"
                    @click.stop="goTransport(item.id,item.customerId)"
                  >查看物流</van-button>-->

                  <!-- <van-button
                    round
                    type="default"
                    hairline
                    plain
                    size="mini"
                    style="padding: 0 0.5rem; border-color: rgb(105, 103, 103)"
                    v-if="item.status == '20'"
                    @click.stop="cancelOrder(item.orderNo)"
                    >取消订单</van-button
                  > -->
                  <!-- <van-button
                    round
                    type="default"
                    hairline
                    plain
                    size="mini"
                    style="padding: 0 0.5rem; border-color: rgb(105, 103, 103)"
                    v-if="item.status == '50'"
                    @click.stop="payRefundOrder(item)"
                    >申请退款</van-button
                  > -->
                  <!-- <van-button
                    round
                    size="mini"
                    style="
                      background: #d84b41;
                      color: #fff;
                      border-color: #d84b41;
                      padding: 0 0.5rem;
                    "
                    v-if="
                      item.generalStatus == '4' || item.generalStatus == '7'
                    "
                    @click.stop="delOrder(item.id)"
                    >删除订单</van-button
                  > -->
                  <!-- <van-button
                    round
                    type="warning"
                    plain
                    hairline
                    size="mini"
                    style="padding: 0 0.5rem"
                    v-if="item.status == '10'"
                    @click.stop="goPay(item)"
                    >去付款</van-button
                  > -->
                </p>
                <!-- <p>
                  <van-button
                    round
                    type="warning"
                    plain
                    hairline
                    size="mini"
                    style="padding: 0 0.5rem"
                    v-if="item.status == '10'"
                    @click.stop="goodsReturnedNote"
                    >退货</van-button
                  >
                </p> -->
              </section>
            </li>
          </ul>
          <!-- <p v-if="!tabs.order.data.length" style="text-align:center;padding:4rem 0 0;" ><img src="../../../../static/images/error.png" alt="" style="width:80%;"></p> -->
        </van-tab>
        <!-- <p slot="loading">
          <img
            src="../../../assets/images/index.messenger-typing-preloader.svg"
            alt
            style="width: 20%"
          />
        </p> -->
        <loading-infinity slot="loading"></loading-infinity>
      </van-list>
    </van-tabs>
  </div>
</template>

<script>
import { Tab, Tabs, Card, Button, List, NavBar } from "vant";
let accessToken = localStorage.getItem("token");
export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Card.name]: Card,
    [Button.name]: Button,
    [List.name]: List,
    [NavBar.name]: NavBar,
  },
  filters: {
    status(status) {
      if (status == 80) {
        return "已取消";
      } else if (status == 10) {
        return "未付款";
      } else if (status == 20) {
        return "待出库";
      } else if (status == 30) {
        return "待收货";
      } else if (status == 40) {
        return "待评价";
      } else if (status == 50) {
        return "已完成";
      } else if (status == 60) {
        return "退款中";
      } else if (status == 70) {
        return "已退款";
      } else if (status == 90) {
        return "支付超时";
      } else if (status == 100) {
        return "支付异常";
      }
      return "无";
    },
  },
  data() {
    return {
      active: this.$route.query.names || 0,
      isLoading: false,
      loading: false,
      finished: false,
      error: false,
      currentPage: 1,
      totalPage: Number,
      checkis: false,
      status: "", // 订单分类状态
      orderList: [],
      goGoodsDetail: this.Base.goGoodsDetail,
      deviceEnvir: this.Base.deviceEnvir(), // 设备环境判断
      size: undefined,
      // tabList: [],
      tabarr: [],
      showDel: false,
      // 退货填写框
      goodsReturnedNoteshow: false,
      wx: this.is_weixn(),
      url_name:"",
    };
  },
  created() {
    // this.$nextTick(() => {
    //   // this.size = document.documentElement.style.fontSize;
    //   this.size = window.getComputedStyle(document.documentElement)["fontSize"];
    //   console.log(this.size);
    //   let showsize = this.size.split("p")[0];
    //   console.log(showsize);

    //   document.documentElement.style.fontSize = showsize / 3.125 + "px";
    // });

    // console.log(this.$route.query.status);

    this.initOrderList(this.$route.query.status || '0');
    let active =  this.$route.query.names ||this.$route.query.active || "0";
    this.active = active;
    // console.log(this.active);
  },
  // beforeDestroy() {
  //   document.documentElement.style.fontSize = this.size;
  // },
  methods: {
    //点击显示退货框
    goodsReturnedNote() {
      this.goodsReturnedNoteshow = true;
    },
    // 返回
    onClickLeft() {
      this.$router.replace("/my");
    },
    // 去开发票
    goBill(orderId) {
      this.$router.push({
        name: "bill",
        params: {
          orderId,
        },
      });
    },
    // // 去发票详情页
    // goBillDetail(orderId) {
    //   this.$router.push({
    //     name: "billdetail",
    //     params: {
    //       orderId,
    //     },
    //   });
    // },
    // 去到订单详情
    goOrderDetail(item) {
      if (item.status == 100) {
        return;
      }
      localStorage.invoiceId =JSON.stringify(item)

      if (item.status === 10) {
        let orderList = [];
        item.skuList.forEach((val, i) => {
          // if (item.skuList.indexOf(val.id) != -1) {
          orderList.push({
            id: val.id,
            img: val.img, // 商品图片
            title: val.spuTitle, // 商品标题
            skuTitle: val.title, // 商品规格
            num: val.num, //商品数量
            price: val.price, //商品价格
            skuId: val.skuId, //商品规格id
            spuId: val.spuId, //商品id
            isshowcar: false,
            takeWay: "cart",
          });
          // }
        });

        localStorage.setItem("skuGoodsList", JSON.stringify(orderList));
        // console.log(
        //   "购物车 取skuGoodsList",
        //   JSON.parse(localStorage.getItem("skuGoodsList"))
        // );
      }
      // console.log(orderNo);
      let orderNo = item.id;
      this.$router.push({ name: "orderdetail", params: { orderNo } });
    },
    // 去付款
    goPay(item) {
      let orderList = [];
      item.skuList.forEach((val, i) => {
        // if (item.skuList.indexOf(val.id) != -1) {
        orderList.push({
          id: val.id,
          img: val.img, // 商品图片
          title: val.spuTitle, // 商品标题
          skuTitle: val.title, // 商品规格
          num: val.num, //商品数量
          price: val.price, //商品价格
          skuId: val.skuId, //商品规格id
          spuId: val.spuId, //商品id
          isshowcar: false,
          orderNo:val.orderNo,

          takeWay: "cart",
        });
        // }
      }); 
      // console.log(orderList);

      localStorage.setItem("skuGoodsList", JSON.stringify(orderList));
      // console.log(
      //   "购物车 取skuGoodsList",
      //   JSON.parse(localStorage.getItem("skuGoodsList"))
      // );
      this.$router.push({
        path: "/orderconfirm",
        query: {
          orderStatus: item.status
        }
      });

      // this.$router.push(`/orderconfirm/${id}`);
    },

    // 支付逻辑，最终与后台交互
    // goPay(orderId) {
    //   // H5支付,(从德合汇跳转到云楚新能源属于H5支付)
    //   const isDehe = localStorage.getItem("isDehe");
    //   // const isDehe = '1';
    //   const openid = localStorage.getItem("openid");
    //   const isHuawei = localStorage.getItem("isHuawei");
    //   // d德合汇跳过来的就H5支付
    //   if (isDehe || isHuawei) {
    //     this.$server
    //       .getH5paramsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           this.goPayResult();
    //           switch (this.deviceEnvir) {
    //             case "IOS":
    //               window.webkit.messageHandlers.invokeiOS.postMessage({
    //                 cmd: "010",
    //                 mweb_url: res.returnValue,
    //               });
    //               break;
    //             case "ANDROID":
    //               window.location.href = res.returnValue;
    //               break;
    //           }
    //         } else {
    //           this.$toast(res.message);
    //           this.goPayResult();
    //         }
    //       });
    //     return false;
    //   }
    //   // 公众号支付
    //   if (openid) {
    //     // 走公众号支付和充值卡
    //     this.$server
    //       .payAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //         openid: localStorage.getItem("openid") || "",
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           // 代表直接用充值卡就支付完毕
    //           if (res.returnValue == 1) {
    //             this.goPayResult();
    //           } else {
    //             // 充值卡余额不够，走了微信支付
    //             let params = { ...res.returnValue }; // 接收后台的返回值
    //             if (typeof WeixinJSBridge === "undefined") {
    //               if (document.addEventListener) {
    //                 document.addEventListener(
    //                   "WeixinJSBridgeReady",
    //                   onBridgeReady,
    //                   false
    //                 );
    //               } else if (document.attachEvent) {
    //                 document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
    //                 document.attachEvent(
    //                   "onWeixinJSBridgeReady",
    //                   onBridgeReady
    //                 );
    //               }
    //             } else {
    //               this.onBridgeReady(params);
    //             }
    //             this.goPayResult();
    //           }
    //         } else {
    //           this.$toast(res.message);
    //         }
    //       });
    //     return false;
    //   }
    //   // APP支付
    //   if (this.deviceEnvir === "IOS") {
    //     // IOS支付
    //     // 获取支付需要的参数
    //     this.$server
    //       .getParamsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           if (res.returnValue == 1) {
    //             // 直接全部走充值卡支付
    //             this.goPayResult();
    //           } else {
    //             let params = res.returnValue;
    //             // 向IOS发送指令
    //             const paramsObj = { cmd: "009", ...params };
    //             window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
    //             this.goPayResult();
    //           }
    //         } else {
    //           this.$toast(res.message);
    //         }
    //       });
    //     return false;
    //   } else if (this.deviceEnvir === "ANDROID") {
    //     // 安卓支付
    //     // 获取支付需要的参数
    //     this.$server
    //       .getParamsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           if (res.returnValue == 1) {
    //             this.goPayResult();
    //             return false;
    //           } else {
    //             let params = res.returnValue;
    //             // 向安卓发送指令
    //             let paramsStr = JSON.stringify({ cmd: "009", ...params });
    //             window.android.invokeAndroid(paramsStr);
    //             this.goPayResult();
    //           }
    //         } else {
    //           this.$toast(res.message);
    //         }
    //       });
    //     return false;
    //   }
    // },
    // 去到询问支付界面
    goPayResult() {
      setTimeout(() => {
        this.$router.replace("/payResult");
      }, 3000);
    },
    // 调起微信支付
    onBridgeReady(params) {
      let that = this;
      WeixinJSBridge.invoke("getBrandWCPayRequest", params, function (res) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          that.$router.replace("/myorder");
        } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
          this.goPayResult();
        } else {
          that.$dialog
            .confirm({
              message: "支付失败",
              showCancelButton: false,
            })
            .then(() => {
              this.goPayResult();
            });
        }
      });
    },
    // 去查看物流信息
    goTransport(orderId, customerId) {
      this.$router.push(`/logistics/${orderId}/${customerId}`);
    },
    // 切换tab的时候
    onChange(index, title) {
      // 再次触发上拉加载
      // console.log(index, title);
      index = 
      index === '全部' ? 0 : 
      index === '待付款' ? 1 :
      index === '待出库' ? 2 :
      index === '待收货' ? 3 :
      index === '待评价' ? 4 :
      index === '退换/售后' ? 5 : ''
      // console.log(index);

      this.orderList = {};
      this.currentPage = 1
      this.initOrderList(index);
      this.pageNo = 1;
      this.pageSize = Number;
      this.finished = false;
    },
    // 更新订单列表
    updateOrder() {
      this.$server
        .initOrderListAddress({
          accessToken: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.code === 100) {
            this.orderList = res.returnValue;
            
          }
        });
    },
    // 去评价
    goComment(row) {
      this.$router.push({
        name: `addComment`,
        params: { row: row },
      });
    },
    // 取消订单
    // delCollect() {
    //   console.log(555454);
    // },

    cancelOrder(id, orderData) {
      this.$api.my
        .cancelOrderAddress({
          accessToken: localStorage.getItem("token"),
          orderNo: id,
        })
        .then((res) => {
          if (res.returnValue) {
            this.$toast({
              message: "取消成功",
              duration: 700,
              onClose: () => {
                this.updateOrder();
              },
            });
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    // 删除订单
    delOrder(orderId) {
      this.$server
        .delOrderAddress({
          accessToken: localStorage.getItem("token"),
          orderId,
        })
        .then((res) => {
          if (res.code === 100 && res.returnValue) {
            this.$toast.success({
              message: "删除成功",
              duration: 700,
              onClose: () => {
                this.updateOrder();
              },
            });
          } else {
            this.$toast(res.message);
          }
        });
    },
    // 申请退款
    payRefundOrder(orderId) {
      console.log(orderId);
       this.$router.push({
        name: "salesReturn",
        params: {
          orderId,
        },
      });
      // this.$api.my
      //   .payRefundOrderAddress({
      //     accessToken: localStorage.getItem("token"),
      //     orderNo: item.orderNo,
      //     userId: localStorage.getItem("customerId"),
      //   })
      //   .then((res) => {
      //     if (res.errno === 200) {
      //       this.$toast.success({
      //         message: "申请退款成功",
      //         duration: 700,
      //         onClose: () => {
      //           this.initOrderList(0);
      //         },
      //       });
      //     } else {
      //       this.$toast(res.errmsg);
      //     }
      //   });
    },

    //开具发票
    Invoice(commodity){
      // console.log(commodity);
      // console.log(commodity.skuList[0]);
      localStorage.setItem("commodity",JSON.stringify(commodity))
      this.$router.push("/repairInvoice")
    }, 

    // 查看发票
    viewInvoice(viewInvoice){

      // localStorage.setItem("viewInvoice",JSON.stringify(viewInvoice))
      // console.log(viewInvoice);
       // 拿发票接口
     
            this.$api.my.checkInvoice({
                id:viewInvoice
            }).then((res) =>{
                if (!res.data.fileUrl) {
                  this.$router.push("/viewInvoice");
                }else{
                  console.log(res.data,'data');
                  let url = res.data.fileUrl.split('?')[0]
                  this.url_name = url.split('/')
                  console.log(this.url_name[this.url_name.length - 1],'url');
                  
                  
                  // window.open(res.data.fileUrl)

                  // let a = document.createElement("a");
                  // a.setAttribute("href",res.data.fileUrl);
                  // a.setAttribute("download","发票");
                  // a.setAttribute("target", "_blank");
                  // a.setAttribute("style", "display:block");
                  // document.body.appendChild(a);
                  // a.click();
                  // document.body.removeChild(a);
                  // var u = window.navigator.userAgent;
                  // var isWeiChat = u.match(/MicroMessenger/i) == "micromessenger"; // 判断是否是微信环境
                  const platfrom = this.Base.deviceEnvir();
                  // console.log(platfrom);
                  // console.log(isWeiChat);

                  if(this.wx){
                    // 微信内打开
                      location.href = res.data.fileUrl
                  }else{
                      if (platfrom == "ANDROID" ) {
                         console.log(1);
                         open(res.data.fileUrl)

                      }else if (platfrom == "IOS") {
                        let filesObj = {
                          link:res.data.fileUrl,
                          fileName:this.url_name[this.url_name.length - 1],
                        }
                        window.webkit.messageHandlers.previewFile.postMessage(filesObj);
                      }
                  }

                
                }
            })
       
      
    },
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    
    /**
     * 表格时间格式化
     * 直接套用时间即可
     */
    formatDate(cellValue) {
      if (cellValue == null || cellValue == "") return "";
      var date = new Date(cellValue);
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    // 获取初始页的订单列表
    initOrderList(index) {
      // console.log('获取订单',index);

      this.loading = true;
      // let arrnum = [undefined, 10, 20, 30, 50, 40, 60, 70, 80];
      let arrnum = [undefined, 10, 20, 30, 40, 60];


      // 获取分类订单
      this.$api.my
        .initOrderListAddress({
          userId: localStorage.getItem("customerId"),
          status: arrnum[index],
          storeId:localStorage.storeId

        })
        .then((res) => {
          // console.log(res.data.items);
          if (res.errno === 200) {
            this.tabarr = [
              // "全部",
              // "待付款",
              // "待发货",
              // "待收货",
              // "完成",
              // "待评价",
              // "退款中",
              // "已退款",
              // "取消",
              "全部",
              "待付款",
              "待出库",
              "待收货",
              "待评价",
              "退换/售后",
            ];

            res.data.returnValue = [];
            for (let i = 0; i < res.data.items.length; i++) {
              // res.data.returnValue.push(arr[i]);

              res.data.items[i].time = this.formatDate(
                res.data.items[i].gmtCreate
              );
            }
            // for(let obj of res.data.returnValue){
            //   if()
            //   obj.children = res.data.
            // }
            this.orderList = res.data;
            // console.log(this.orderList);
            // console.log('当前激活',index);
            // this.active = index;
            // return
            // console.log(index);
            // if (index != 0) {
            //   this.status = this.orderList.items[0]
            //     ? this.orderList.items[0].status
            //     : "";
            //     console.log(this.orderList.items[0]);
            // } else {
            //   this.status = undefined;
            // }
            this.status = index == 0 ? 
            undefined : index == 1 ? 
            10 : index == 2 ? 
            20 : index == 3 ?
            30 : index == 4 ?
            40 : 60 
            
            // console.log(this.status,'this.status');

            this.totalPageNo = this.orderList.totalPageNo;
            this.isLoading = false;
            this.loading = false;
          }
        });
    },
    cancatOrderList() {
      console.log(this.currentPage);
      this.currentPage++;
      if (this.currentPage > this.totalPageNo) {
        this.loading = false;
        return (this.finished = true);
      }
      // 未登录的状态就不发起请求
      // this.$server
      //   .cancatOrderListAddress({
      this.$api.my
        .initOrderListAddress({
          userId: localStorage.getItem("customerId"),
          pageNo: this.currentPage,
          status: this.status,
          storeId:localStorage.storeId
        })
        .then((res) => {
          if (res.errno === 200) {
            console.log(5645465456);
            this.loading = false; // 关闭上拉加载中
            // console.log(res);
            // console.log(this.orderList);
            // console.log(this.status);
            // this.orderList.items.forEach((item) => {
            //   console.log(item);
            // if (this.status === item.status) {
            this.orderList.items = this.orderList.items.concat(res.data.items);
            // }
            // });
          } else {
            this.error = true;
          }
        });
    },
    onLoad() {
      // 异步获取数据
      setTimeout(() => {
        this.cancatOrderList();
      }, 2000);
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../../less/element.less";
// @red: #ca3e3d;
.myorder {
  width: 100%;
  box-sizing: border-box;
  padding: 1.72rem 0 0;
  .van-nav-bar {
    .van-icon {
      color: #ccc;
      font-size: 0.45rem;
    }
    .van-nav-bar__title {
      // color: #d84b41;
      letter-spacing: 2px;
    }
  }
  .myorder-tab {
    & /deep/ .van-tabs__wrap {
      position: fixed;
      width: 100%;
      top: 0.82rem;
      left: 0;
      z-index: 300;
    }
    & /deep/ .van-tab {
      & /deep/ .van-ellipsis {
        font-size: 0.32rem;
      }
    }
    & /deep/ .van-tabs__nav {
      background-color: #fff;
    }
    & /deep/ .van-tabs__content {
      padding: 0 0.16rem;
    }
  }
  .myorder-list {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0.06rem;
    margin-top: 0.24rem;
    padding: 0 0 0.32rem;
    li {
      margin: 0 0 0.16rem;
      padding: 0.32rem 0;
      background-color: #fff;
      border-radius: 0.12rem;
      & > p {
        padding: 0 0.24rem;
        line-height: 0.44rem;
        font-size: 0.24rem;
        .list(row);
        justify-content: space-between;
        color: @ordinary_text_color;
      }
      & > section {
        text-align: right;
        padding: 0 0.24rem;
        p {
          line-height: 0.56rem;
          font-size: 0.24rem;
          &:first-child {
            font-size: 0.24rem;
          }
        }
      }
      .van-card {
        background-color: #fff;
      }
    }
  }
}
.dark_shop_bg {
  margin: 5% 0 0 5%;
}
.myorder {
  min-height: 100vh;
  height: 100%;
  // background-color: #f0eded;
}
.showDel-text {
  margin: 0.64rem;
  font-size: 0.38rem;
  text-align: center;
}
.van-card__price {
  font-size: 0.3rem;
}
.myorder-btn{
  margin-right: .3rem;
  padding: 0;
  width: 1.5rem;
  height: .6rem;
  background: #FFFFFF;
  border: 1px solid #C0C0C0;
  border-radius: .1rem;
  font-size: .26rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: .32rem;
}
/deep/ .van-tab--active  {
  color: @tab_title_active_color;
}
/deep/ .van-tabs__line {
  background-color: @tabs_line_active_color;
}
</style>
